import React from "react";
import Layout from "../../../components/layout";
import Seo from "../../../components/seo";
import TopBanner from "../../../components/database/topbanner/TopBanner";

import snowflakeImg from "../../../components/databasewaitlist/images/snowflake.svg";
const ogImage = {
  ogImage: "https://graphql-engine-cdn.hasura.io/assets/main-site/snowflake_rest.png",
};

const canonicalUrl = "https://hasura.io/rest-api/database/snowflake/";

const Snowflake = props => (
  <Layout location={props.location}>
    <Seo
      title="Instant Realtime GraphQL and REST APIs on Snowflake - Hasura"
      description=" Connect Hasura to existing/new Snowflake data for instant, realtime GraphQL and REST APIs."
      meta={ogImage}
      canonicalLink={canonicalUrl}
    />
    <TopBanner
      title="Instant REST APIs on Snowflake"
      desc="Point to your Snowflake instance and get secure and performant and REST APIs in under 2 minutes!"
      btnLink="https://cloud.hasura.io/signup?pg=snowflake&plcmt=hero&cta=try-it-out-in-30s&tech=default"
      btnText="Try it out in 30 seconds"
      imgSrc={snowflakeImg}
      linkUrl="https://hasura.io/blog/introducing-instant-graphql-apis-for-snowflake-cloud-data-platform/"
      linkText="Read launch blog"
      secondaryLinkUrl="https://hasura.io/contact-us/?pg=snowflake&plcmt=hero&cta=request-a-demo&tech=default"
      secondaryLinkText="Request a demo"
    />
  </Layout>
);

export default Snowflake;
